import { isTouchEnabled } from '@/utils/Common/Utils';
import CONST from '@/utils/Constants/General';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';
import { LazySwiperLatestWhenVisible } from '@/utils/Controllers/LazySwiperLatestController';

let gsap;
let sliderIsMoving = false;
const SHELF_WRAPPER_SELECTOR = '.js-bra-category-shelf';

// filter
const SHELF_FILTERS_SWIPER_SELECTOR = '.js-bra-category-shelf__swiper';
const SHELF_FILTER_SELECTOR_SELECTOR =
  '.js-bra-category-shelf__filter-selector';

// toggle
const SHELF_TOGGLE_SELECTOR = '.js-category-shelf__toggle';

// grid
const SHELF_GRID_SELECTOR = '.js-bra-category-shelf__grid';

// card
const SHELF_CARD_SELECTOR = '.js-bra-category-shelf__card';
const SHELF_CARD_INFO_SELECTOR = '.js-bra-category-shelf__card-info';
const SHELF_DETAILS_WRAPPER_SELECTOR =
  '.js-bra-category-shelf__card-details-wrapper';
const SHELF_MASK_SELECTOR = '.js-bra-category-shelf__card-mask';
const SHELF_IMAGE_SELECTOR = '.js-bra-category-shelf__card-image';
const SHELF_IMAGE_HOVER_SELECTOR = '.js-bra-category-shelf__card-imageHover';

// other
const HOVER_CLASS = '-hover';

class CategoryShelfController {
  constructor(el, SwiperLib) {
    this.el = el;
    this.SwiperLib = SwiperLib;
    this.filterSwiperEl = this.el.querySelector(SHELF_FILTERS_SWIPER_SELECTOR);
    this.filterSelectorEl = this.el.querySelector(
      SHELF_FILTER_SELECTOR_SELECTOR,
    );
    this.toggleEl = this.el.querySelector(SHELF_TOGGLE_SELECTOR);
    this.gridEls = Array.from(this.el.querySelectorAll(SHELF_GRID_SELECTOR));
    this.cardEls = this.el.querySelectorAll(SHELF_CARD_SELECTOR);
    this.detailEls = this.el.querySelectorAll(SHELF_DETAILS_WRAPPER_SELECTOR);

    this.lastClickedIndex = 0;
    this.lastClickedSlide = null;
    this.timeline = gsap.timeline();

    this.initToggle = this.initToggle.bind(this);
    this.cardHoverAnimation = this.cardHoverAnimation.bind(this);
    this.selectorAnimation = this.selectorAnimation.bind(this);
    this.updateSelectorPosition = this.updateSelectorPosition.bind(this);
    this.bindEvents();
    this.initFilter();
  }

  bindEvents() {
    this.toggleEl.addEventListener('change', this.initToggle);
    Array.from(this.cardEls).forEach(card => {
      card.addEventListener('mouseenter', this.cardHoverAnimation);
      card.addEventListener('mouseleave', this.cardHoverAnimation);
    });
  }

  gridAnimation() {
    if (this.timeline) this.timeline.pause();
    this.timeline = gsap.timeline();
    const nextGridEl = this.gridEls.find(
      el => el.dataset.uid === this.lastClickedSlide.dataset.uid,
    );
    this.timeline
      .set(this.gridEls, { display: 'none' })
      .fromTo(
        nextGridEl,
        { display: 'none', opacity: 0 },
        { display: 'grid', opacity: 1, duration: 1 },
      );
  }

  selectorAnimation() {
    const slideRect = this.lastClickedSlide.getBoundingClientRect();
    gsap.set(this.swiper.slides, { color: CONST.COLOR.BLACK });
    gsap.set(this.filterSelectorEl, { width: slideRect.width });
    gsap.set(this.lastClickedSlide, { color: CONST.COLOR.WHITE });
    gsap.to(this.filterSelectorEl, {
      x: this.lastClickedSlide.offsetLeft,
      duration: 0.3,
    });
    this.slideSwiper(this.lastClickedIndex, 300);
    this.gridAnimation();
  }

  updateSelectorPosition() {
    const slideRect = this.lastClickedSlide.getBoundingClientRect();
    gsap.set(this.filterSelectorEl, { width: slideRect.width });
    gsap.set(this.filterSelectorEl, {
      x: this.lastClickedSlide.offsetLeft,
    });
  }

  slideSwiper(slideIndex, time) {
    try {
      const slides = Array.from(
        this.filterSwiperEl.querySelectorAll('.swiper-slide'),
      );
      const realWidth = slides.reduce((acc, curr) => acc + curr.offsetWidth, 0);
      if (realWidth > this.filterSwiperEl.offsetWidth) {
        this.swiper.slideTo(slideIndex, time);
      }
    } catch (e) {
      console.error(e);
    }
  }

  initFilter() {
    this.swiper = new this.SwiperLib(this.filterSwiperEl, {
      resizeObserver: true,
      slidesPerView: 2.5,
      resistanceRatio: 0,
      // allowTouchMove: false,
      touchStartPreventDefault: false,
      freeMode: {
        enabled: true,
        sticky: false,
      },
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesOffsetAfter: 20,
      breakpoints: {
        768: {
          slidesPerView: 3.2,
          slidesOffsetAfter: 20,
        },
        1200: {
          slidesOffsetAfter: 30,
          slidesPerView: 5,
        },
        1400: {
          slidesOffsetAfter: 0,
          slidesPerView: 5,
        },
      },
    });

    // init setup
    this.lastClickedSlide = this.swiper.slides[this.lastClickedIndex];
    this.lastClickedSnapGrid = this.swiper.snapGrid[this.lastClickedIndex];
    this.selectorAnimation(0);
    gsap.to(this.filterSwiperEl, { opacity: 1 });

    // binding events
    const onClickHandler = e => {
      // if sliderIsMoving we should avoid to run this event callback.
      if (sliderIsMoving) {
        sliderIsMoving = false;
        return;
      }
      const clickedIndex = e.target.dataset.index;
      const { snapGrid, slides } = this.swiper;
      if (this.lastClickedIndex === clickedIndex) return;
      this.lastClickedIndex = clickedIndex;
      this.lastClickedSlide = slides[clickedIndex];
      this.selectorAnimation(snapGrid[clickedIndex] - this.lastClickedSnapGrid);
      this.lastClickedSnapGrid = snapGrid[clickedIndex];
    };

    Array.from(this.swiper.slides).forEach(slide => {
      if (isTouchEnabled()) {
        slide.addEventListener('touchend', onClickHandler);
        slide.addEventListener('mouseup', () => {
          sliderIsMoving = false;
        });
      } else {
        slide.addEventListener('mouseup', onClickHandler);
      }
    });
    this.swiper.on('resize', () => {
      this.lastClickedSnapGrid = this.swiper.snapGrid[this.lastClickedIndex];
      this.slideSwiper(this.lastClickedIndex, 0);
      this.updateSelectorPosition();
    });

    this.swiper.on('sliderMove', () => {
      // on slider move, we block any unwanted click on slide, setting this variable to true.
      sliderIsMoving = true;
      this.updateSelectorPosition();
    });
  }

  initToggle() {
    const tl = gsap.timeline();
    if (this.toggleEl.checked) {
      this.el.dataset.hasDetailsOpen = true;
      tl.set(this.detailEls, { display: 'block' }).to(this.detailEls, {
        height: 'auto',
        opacity: 1,
        duration: 0.3,
      });
    } else {
      delete this.el.dataset.hasDetailsOpen;
      tl.to(this.detailEls, { height: 0, opacity: 0, duration: 0.3 }).set(
        this.detailEls,
        { display: 'none' },
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  cardHoverAnimation(e) {
    const { currentTarget } = e;
    const hasValidMM = () => window.matchMedia('(min-width:1200px)').matches;
    if (!hasValidMM()) return;
    const isEnter = e.type === 'mouseenter';
    const infoBox = currentTarget.querySelector(SHELF_CARD_INFO_SELECTOR);
    const mask = currentTarget.querySelector(SHELF_MASK_SELECTOR);
    const image = currentTarget.querySelector(SHELF_IMAGE_SELECTOR);
    const imageHover = currentTarget.querySelector(SHELF_IMAGE_HOVER_SELECTOR);
    infoBox.classList.toggle(HOVER_CLASS, isEnter);
    gsap.set(mask, { display: isEnter ? 'block' : 'none' });
    gsap.to(mask, { opacity: isEnter ? 1 : 0 });
    gsap.to(image, { opacity: isEnter ? 0 : 1 });
    gsap.to(imageHover, {
      display: isEnter ? 'block' : 'none',
      opacity: isEnter ? 1 : 0,
    });
  }
}

const initController = async () => {
  const entries = SmarteditController.getEditorialEntries(
    SHELF_WRAPPER_SELECTOR,
  );

  if (!_isEmpty(entries)) {
    LazySwiperLatestWhenVisible(entries, async (target, SwiperLib) => {
      ({ gsap } = await LazyGsap());
      // eslint-disable-next-line no-new
      new CategoryShelfController(target, SwiperLib);
    });
  }
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initController);
  initController();
};
